@import "../../../styles/variables";

.welcome-modal {
  .header {
    color: $gray-800;
    font-size: 1.2rem;
    text-align: center;
    padding: 20px;
  }

  .steps-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-align: center;
  }

  .step-container {
    .step-flex {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .row {
      div:first-of-type {
        @extend .step-flex;
        font-size: 6rem;
        background-color: $gray-100;
        border: 1px solid $gray-200;
        border-radius: 10px;

        svg { margin: 50px; }
      }
    }
  }

  .step {
    padding: 20px;
    background-color: $gray-100;
    border: 1px solid $gray-200;
    border-radius: 10px;

    svg {
      display: block;
      margin-right: auto;
      margin-left: auto;
      font-size: 7rem;
      padding: 20px;
      color: #333;
    }

    &:hover {
      cursor: pointer;
      background-color: $gray-200;
    }
  }

  .xero-api-feature-list {
    list-style-type: none;
    padding-left: 0;
  }
}
