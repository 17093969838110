@import "../../../styles/variables";

.article-feedback-container {
  text-align: center;

  .button-container {
    > button {
      margin: 20px;
      padding: 5px 20px;
      box-shadow: $shadow-sm;
    }
  }
}
