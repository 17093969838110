@import "../../../styles/variables";

.edit-transaction-modal {
  .actions-container {
    display: flex;
    justify-content: space-between;

    > div {
      text-align: center;
      background: $gray-100;
      border: 1px solid $gray-200;
      padding: 10px;
      margin: 10px;

      &:hover {
        background-color: $primary;
        border-color: darken($primary, 5);
        cursor: pointer;
        color: white;
      }
    }
  }

  .error-container {
    background-color: lighten($danger-light, 30);
    border: 1px solid $danger-light;
    padding: 5px 20px;
    margin: 20px;

    p {
      margin-bottom: 10px;
      margin-top: 10px;
    }
  }
}
