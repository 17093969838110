@import "../../../styles/variables";


.map-listing-container {
  background-color: $gray-100;
  border: 1px solid $gray-200;

  .input-group {
    justify-content: center;
  }

  .loading-container {
    padding: 10%;
  }
}
