@import "../../../styles/variables";

.transaction-row {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-top: 1px solid $gray-400;

  transition: border-color 0.3s ease;

  .edit-icon { display: none; }

  &:hover {
    background-color: $secondary;

    .edit-icon { display: inline; }
  }

  &.error {
    background-color: $danger-light;
    color: white;
  }
}
