@import "../../../styles/variables";

.auth-route {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $gray-100;

  .auth-content {
    background-color: white;
    width: 350px;
    padding: 50px;
  }

  .auth-extra-container {
    a {
      display: block;
      margin: 10px 0;
    }
  }
}
