@import "../../styles/variables";

.landing-route {
  $bg-image-height: 80vh;

  background-color: white!important;
  background-image: url('/bg-welcome.png');
  background-size: 100% $bg-image-height;
  background-repeat: no-repeat;

  section {
    padding: 100px;

    .section-title {
      text-align: center;
      font-size: 4rem;
    }
  }

  .welcome-section {
    height: $bg-image-height;
    color: white;
    padding-bottom: 200px;
    display: flex;
    justify-content: space-around;
    align-items: center;

    // navbar height
    margin-top: 100px;

    .heading-container {
      display: flex;
      justify-content: center;
      flex-direction: column;


      .btn {
        width: 50%;
        border-radius: 25px;
      }

      p {
        max-width: 550px;
        font-size: 1.1rem;
        margin-bottom: 20px;
      }

      h1 { font-size: 5rem; }
    }

    .carousel-container {
      width: 600px;
      box-shadow: 0 0 50px rgba(0, 0, 0, 0.9);
      flex-basis: 45%;
    }
  }

  .features-section {
    .feature {
      padding: 100px 40px;

      > div { padding: 80px; }
    }

    .feature-description {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: lighten($dark, 30%);

      h4 {
        width: 100%;
        text-align: left;
        color: darken($primary, 10%);
        font-weight: 500;
      }
    }

    img {
      width: 100%;
      height: auto;
      box-shadow: $shadow-lg;
      border-radius: 8px;
    }
  }

  .pricing-section {
    background-color: $gray-100;
    border-top: 1px solid $gray-200;
    border-bottom: 1px solid $gray-200;
  }

  .contact-section {
    .section-title {
      margin-bottom: 40px;
    }

    .contact-form-container {
      padding: 20px;
      background-color: white;
      border: 1px solid $gray-200;
      border-radius: 5px;

      textarea {
        height: 200px;
      }
    }
  }

  @media screen and (max-width: $screen-sm) {
    // mobile css
    section {
      padding: 15px;
    }

    .heading-container {
      align-items: center;
    }

    .features-section {
      .feature {
        padding: 20px 20px;

        > div { padding: 0; }
      }

      .feature-image {
        display: none;
      }
    }

    .pricing-section {
      .payment-options {
        margin: 0 10px;
      }

      .col {
        width: 100%;
      }
    }
  }

  @media screen and (max-width: $screen-lg) {
    .how-it-works-section {

    }
  }
}
