@import "../../../styles/variables";

.topic-block {
  display: flex;
  height: 100%;

  &:hover {
    background-color: darken(white, 1%);
    cursor: pointer;
  }

  svg {
    color: $primary;
    font-size: 1.5rem;
  }

  .topic-description {
    color: $gray-600;
  }

  .icon-container {
    padding-right: 20px;
    padding-top: 10px;
  }

  .topic-content-container {

  }
}
