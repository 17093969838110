.toast-body {
  .Toastify__toast {
    align-items: center;
  }

  .Toastify__toast--default {
    background-color: $primary;
    color: white;
  }

  .Toastify__toast--success {
    background-color: $success;
  }

  .Toastify__toast--warning {
    background-color: $warning;
  }
}
