@import "../../styles/variables";

.dot-status-container {
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    margin: 10px;
    color: $primary;
  }
}
