@import "../../../styles/variables";

.articles-container {
  padding-right: 80px;

  .article-preview-container {
    padding-bottom: 20px;
    border-bottom: 1px solid $light;
    margin-bottom: 20px;
    border-left: 5px solid white;
    padding-left: 15px;

    > p { color: $gray-600; }

    h3 { transition: 0.3s ease all; }

    &:hover {
      cursor: pointer;

      h3 {
        border-left: 5px solid $primary;
        padding-left: 15px;
      }
    }

    &:last-of-type {
      padding-bottom: 0px;
      border-bottom: none;
    }
  }
}
