@import "../../../styles/variables";


.import-properties-modal {
  .loading-container {
    padding: 20%;
  }

  .help-text {
    border-bottom: 1px solid $gray-500;
  }
}
