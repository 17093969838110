@import "../../../styles/variables";

.payment-options-row {

  .col {
    background-color: white;
    margin: 20px;
    padding: 50px;
    border: 1px solid $gray-200;
    border-radius: 5px;
    text-align: center;

    h4 {
      font-size: 1.5rem;
      font-weight: 500;
    }

    p { color: lighten($dark, 20%); }

    .price {
      font-size: 2rem;
      font-weight: 500;
      color: $dark;
    }

    .old-price {
      font-size: 1rem;
      font-weight: 300;
      text-decoration: line-through;
      color: $danger;
    }

    .features > svg {
      margin-top: 20px;
      color: $success;
    }

    .promotional-desc {
      color: darken($primary, 10);
      font-size: 1.1rem;
    }
  }
}
