form {
  .form-title {
    font-size: 2rem;
    font-weight: 100;
    text-align: center;
    margin-bottom: 45px;
  }

  .form-group {
    input {
      // margin: 10px 0;
    }
  }

  .submit-container {
    text-align: right;
  }
}
