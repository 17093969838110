@import "../../../styles/variables";

.dashboard-route {
  display: flex;
  height: 100vh;

  $sidenav-width: 150px;

  .sidenav-section {
    height: 100vh;
    overflow-y: hidden;
    min-width: $sidenav-width;

    nav {
      color: white;
    }

    a {
      display: block;
      padding: 5px;
      padding-left: 15px;
      font-size: 1.1rem;
      color: white;
      transition: 0.3s ease background-color;

      &:hover {
        text-decoration: none;
        background-color: $secondary;
      }

      &.active {
        background-color: $primary
      }
    }

    .dashboard-nav-logo {
      width: $sidenav-width;
    }
  }

  .sidenav-layout-content {
    width: 100%;
    overflow-y: scroll;
  }
}
