.autocomplete-item {
  padding: 10px;

  .form-control {
    min-width: 250px;
  }

  &.highlighted {
    background-color: $secondary;
  }

  &:hover {
    cursor: pointer;
    background-color: lighten($secondary, 10);
  }
}
