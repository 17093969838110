@import "../../../styles/variables";

.usage-route {
  .exempt-section {
    background-color: $success;
    color: white;
  }

  .data-section {
    padding-right: 0;
    max-height: 800px;
    overflow-y: scroll;
  }
}
