@import "../../../styles/variables";

.cleaning-fee-warning {
  color: red;
  font-style: italic;
  padding-top: 10px;
}
.xero-invoice {
  transition: 0.3s ease all;

  .invoice-header {
    .col {
      display: flex;
      align-items: center;
    }
  }

  &.invalid {
    background-color: white;
  }

  &.selected {
    background-color: $success-light;
    border-color: darken($success-light, 10);

    .transaction-row { border-color: $gray-800; }

    &:hover {
      background-color: darken($success-light, 5);
      background-color: darken($success-light, 10);
    }
  }

  &:hover {
    cursor:pointer;
    background-color: $gray-200;
    border-color: $gray-400;
  }
}
