.help-navbar {
  height: 100px;

  .navbar-brand {
    font-size: 2rem;
    font-weight: 700;
    color: white!important;

    &:hover { cursor: pointer; }
  }

  .navbar-nav {
    .btn {
      border-radius: 20px;
      text-transform: uppercase;
    }
  }
}
