@import "../../../styles/variables";

.topic-sidebar {
  .active {
    border-left: 5px solid $primary;
    color: darken($primary, 15);
  }

  > p {
    padding: 0px 10px;
    border-left: 5px solid white;

    &:hover { cursor: pointer; }
  }
}
