@import "../../../styles/variables";

.landing-welcome-carousel {
  .carousel-caption {
    color: $dark;
  }

  span, p {
    color: $dark;
  }

  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    height: 100px;
    width: 100px;
    outline: black;
    background-size: 100%, 100%;
    background-image: none;

    &:after {
      font-size: 2rem;
      color: $dark;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .carousel-control-next-icon:after
  {
    content: '>';
  }

  .carousel-control-prev-icon:after {
    content: '<';
  }

  .carousel-indicators {
    li {
      background-color: $dark;
    }
  }

  img {
    width: 100%;
    height: auto;
  }
}
