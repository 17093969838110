.payments-route {

}

.creating-invoices-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;

  .loading-container {
    height: 80px!important;
  }
}
