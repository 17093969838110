@import "../../../styles/variables";

.features-container {
  text-align: left;
  margin-left: 20px;

  svg {
    color: $success;
    margin-right: 5px;
  }
}
