@import "../../../styles/variables";

.trial-balance-row {
  background-color: white;

  &:hover {
    cursor: pointer;
  }

  &.selected {
    background-color: $success-light;
    border-color: $success!important;
  }
}
