@import "../../../styles/variables";

.help-search {
  // Override bootstraps blue glow.
  $border: 1px solid #ccc !important;

  svg {
    color: $primary;
  }

  input {
    border-left: none;
  }

  .input-group-text {
    background-color: white;
  }

  .input-group-append {
    &:hover {
      cursor: pointer;
      color: darken($primary, 10%);
    }
  }

  input[type="text"], textarea {
    outline: none;
    box-shadow:none !important;
    border-top: $border;
    border-bottom: $border;
    border-right: $border;
  }
}
