.export-route {
  .export-options {
    h4 { text-align: center; }

    .button-container { text-align: center; }
  }

  .invoices-header {
    text-align: center;
  }

  .btn-export-invoices, .btn-download-csv {
    width: 150px;
  }

  button {
    svg {
      margin-left: 5px;
      margin-right: 5px;
    }
  }
}
