@import "../../styles/variables";


.drop-zone {
  background-color: $gray-100;
  border: 3px dashed $gray-300;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s background-color ease;

  > p { margin-bottom: 0; }

  &:hover {
    cursor: pointer;
  }

  &.has-file {
    background-color: $success-light;
    border-color: $success;
  }
}
