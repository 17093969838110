@import "../../../styles/variables";

.stripe-checkout {
  .card-element {
    margin-top: 40px;
    margin-bottom: 20px;
    border: 1px solid $light;
    padding: 15px;
  }
  .btn-checkout {
    width: 150px;
  }
}
