@import "../../../styles/variables";

.change-listing-modal {
  .select-control-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .source-listing, .xero-account {
    color: $primary;
  }
}
