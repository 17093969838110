.table {
  margin-bottom: 0;
  
  .clickable-row:hover {
    background-color: $secondary;
    cursor: pointer;
    transition: 0.3s background-color ease;

    a { color: white; }
  }
}

.table-container {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
