@import "../../../styles/variables";

.export-steps-container {
  .export-steps {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 20px 50px;

    > div, .fa-chevron-right {
      color: $gray-500;

      &.active {
        color: $dark!important;
        font-size: 1.2rem;
      }
    }
  }
}
