@import "../../styles/variables";

.landing-navbar {
  background-color: white;
  border-bottom: 1px solid $light;
  height: 100px;

  a {
    color: $dark;
    font-size: 1.2rem;
  }

  .navbar-brand {
    font-size: 2rem;
    font-weight: 700;
  }

  .navbar-nav {
    text-transform: uppercase;

    > li {
      margin-left: 20px;
      margin-right: 20px;
    }

    a:hover {
      cursor: pointer;
    }

    .btn {
      border-radius: 20px;
      text-transform: uppercase;
    }
  }

  .navbar-collapse {
    background-color: rgba(255,255,255,0.95);
    padding: 25px;
  }
}
