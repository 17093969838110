@import "../../../styles/variables";

.terms-of-service-route {
  .navbar {
    background-color: $primary;

    .navbar-brand {
      display: flex;
      align-items: center;
      color: white!important;

      > span {
        margin-left: 5px;
        margin-bottom: 2px;
      }
    }

    .navbar-nav {
      flex-direction: row;

      .nav-item {
        padding: 12px;

        a { color: $dark; }
      }
    }
  }

  main {
    margin: 5% 10%;
    padding: 20px;
    border: 1px solid darken($light, 5%);
    background-color: white;
  }
}
