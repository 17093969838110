@import "../../../styles/variables";

.user-settings-form {
  select { width: inherit; }

  .toggle-form-group {
    display: flex;
    align-items: center;
  }

  .submit-container {
    button { width: 130px;}
  }

  .btn-disconnect {
    &:hover { cursor: pointer; }
  }
}
